import { Cancel, Close, Publish } from "@mui/icons-material";
import { Alert, Button, CircularProgress, IconButton, Snackbar } from "@mui/material";
import React, { useRef } from "react";
import "./Styles/JobPhotos.css";
import { useAuth } from "../../../../Contexts/AuthContext";
import { useParams } from "react-router-dom";
import { db, storage } from "../../../../Firebase";
import firebase from "firebase/compat/app";

function JobPhotos({ color }) {
  // hooks
  const { currentUser } = useAuth();
  const { user } = useParams();

  // state variables
  const [state, setState] = React.useState({
    currentUser: null,
    loading: false,
    images: [],
    errorMessage: "",
    errorOpen: false,
    successMessage: "",
    successOpen: false,
    infoMessage: "",
    infoOpen: false,
  });

  React.useEffect(() => {
    async function handleFetch() {

      db.collection("requests")
        .doc(currentUser.uid)
        .get()
        .then((snapshot) => {
          if (snapshot.exists) {
            setState((state) => ({
            ...state,
            loading: false,
            images: snapshot && snapshot.data().images ? snapshot.data().images : [],
          })); 
          } else {
            setState((state) => ({
              ...state, 
              loading: false,
            }))
            return;
          }
         
        });
    }
    handleFetch();
  }, [currentUser]);

  async function handleImageUpload(e) {
    const arr = [];
    setState((state) => ({
      ...state,
      loading: true,
    }));
    for (let i = 0; i < e.target.files.length; i++) {
      const newImage = e.target.files[i];
      const imageName = `${e.target.files[i].name}_${Math.random() * 100}`;
      await storage.ref(`${user}/request_images/${imageName}`).put(newImage);
      const url = await storage
        .ref(`${user}/request_images/`)
        .child(imageName)
        .getDownloadURL();
      await arr.push({ title: imageName, url: url });

      // handle adding to firestore
      setState((state) => ({
        ...state,
        images: [...state.images, { title: imageName, url: url }],
      }));

      db.collection("requests")
        .doc(currentUser.uid)
        .set(
          {
            images: firebase.firestore.FieldValue.arrayUnion({
              title: imageName,
              url: url,
            }),confirm: false
          },
          { merge: true }
        );
    }

    await Promise.all(arr).then((r) => {
      setState((state) => ({
        ...state,
        loading: false,
      }));
    });

    console.log({ arr });
    return arr;
  }

  const ref = useRef();

  // handle removing an image

  async function handleImageRemove(image) {
    if (window.confirm("are you sure you want to delete this image?")) {
      // handle image delete
      const { title } = image;

      // set loading
      setState((state) => ({
        ...state,
        loading: true,
      }));

      db.collection("requests")
        .doc(currentUser.uid)
        .update({
          images: firebase.firestore.FieldValue.arrayRemove(image),
          confirm: false,
        })
        .then(async (r) => {
          //success
          await storage
            .ref(`${user}/request_images/`)
            .child(title)
            .delete()
            .then((x) => {
              //success

              db.collection("requests")
                .doc(currentUser.uid)
                .get()
                .then((r) => {
                  setState((state) => ({
                    ...state,
                    images: r.data().images,
                  }));
                });
              setState((state) => ({
                ...state,
                loading: false,
                errorMessage: "",
                errorOpen: false,
                successMessage: "Successfully deleted image...",
                successOpen: true,
                infoMessage: "",
                infoOpen: false,
              }));
            })
            .catch((err) => {
              //error
              setState((state) => ({
                ...state,
                loading: false,
                errorMessage: "Failed to remove image...",
                errorOpen: true,
                successMessage: "",
                successOpen: false,
                infoMessage: "",
                infoOpen: false,
              }));
            });
        })
        .catch((err) => {
          // error
          setState((state) => ({
            ...state,
            loading: false,
            errorMessage: "Failed to remove image...",
            errorOpen: true,
            successMessage: "",
            successOpen: false,
            infoMessage: "",
            infoOpen: false,
          }));
        });
    } else {
      setState((state) => ({
        ...state,
        infoOpen: true,
        infoMessage: "Image delete cancelled...",
        errorOpen: false,
        errorMessage: "",
        successMessage: "",
        successOpen: false,
      }));
    }
  }


    // handle snackbar jazz 
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setState((state) => ({
        ...state,
        successMessage: '',
        successOpen: false,
        errorMessage: '', 
        errorOpen: false, 
        infoMessage: '', 
        infoOpen: false
      }));
    };
  
    const [vertical] = React.useState("bottom");
    const [horizontal ] = React.useState("right");


  return (
    <div className="JobPhotos">
      <Snackbar
            open={state.successOpen}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
          >
            < Alert onClose={handleClose} severity="success">
              {state.successMessage}
            </Alert>
          </Snackbar>
          <Snackbar
            open={state.infoOpen}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
          >
            < Alert onClose={handleClose} severity="info">
              {state.infoMessage}
            </Alert>
          </Snackbar>
          <Snackbar
            open={state.errorOpen}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
          >
            < Alert onClose={handleClose} severity="error">
              {state.errorMessage}
            </Alert>
          </Snackbar>


      {state.loading && (
        <div className="loading">
          <CircularProgress style={{ color }} />
        </div>
      )}

      <div className="title">Upload Images</div>
      <div className="subtitle">
        Photos help us know whats going on, please add as many photos as you
        like!
      </div>

      <div className="images">
        {state.images &&
          state.images.map((item, ind) => (
            <div className="image-master">
              <img key={ind} src={item.url} className="image" />
              <IconButton
                className="icon"
                onClick={(e) => {
                  handleImageRemove(item);
                }}
              >
                <Close style={{ color }} />
              </IconButton>
            </div>
          ))}
      </div>

      <div className="button-section">
        <input
          accept="image/*"
          multiple
          type="file"
          onChange={(e) => {
            handleImageUpload(e);
          }}
          ref={ref}
          style={{ visibility: "hidden" }}
        />

        <Button
          style={{ color }}
          onClick={(e) => {
            ref.current.click();
          }}
        >
          <Publish />
          Upload
        </Button>
      </div>
    </div>
  );
}

export default JobPhotos;
