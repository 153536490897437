import styled from "@emotion/styled";
import { Alert, Checkbox, Snackbar, TextField } from "@mui/material";
import React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useAuth } from "../../../../Contexts/AuthContext";
import { db } from "../../../../Firebase";
import "./Styles/Contact.css";

function Contact({ color }) {
  // hooks
  const { currentUser } = useAuth();
  // state variables
  const [state, setState] = React.useState({
    phonePreferred: true,
    tel: "",
    email: "",
    name: "",
    successMessage: '',
        successOpen: false,
        errorMessage: '', 
        errorOpen: false, 
  });

  const [value, setValue] = React.useState("");

  function handleSave() {
    // handle save
    db.collection("requests")
      .doc(currentUser.uid)
      .set(
        {
          tel: state.tel,
          email: state.email,
          name: state.name,
          address: value,
          phonePreferred: state.phonePreferred, 
          confirm: false
        },
        { merge: true }
      )
      .then((r) => {
        setState((state) => ({
          ...state,
          successMessage: "Successfully saved progress...",
          successOpen: true,
          errorMessage: "",
          errorOpen: false,
        }));
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          successMessage: "",
          successOpen: false,
          errorMessage: "Failed to save progress...",
          errorOpen: true,
        }));
      });
  }

  React.useEffect(() => {
    db.collection("requests")
      .doc(currentUser.uid)
      .get()
      .then((r) => {
        setState((state) => ({
          ...state,
          tel: r.data() && r.data().tel ? r.data().tel : "",
          email: r.data() && r.data().email ? r.data().email : "",
          name: r.data() && r.data().name ? r.data().name : "",
        }));
        setValue(r.data() && r.data().address ? r.data().address : "");
      });
  }, [currentUser]);



    // handle snackbar jazz 
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setState((state) => ({
        ...state,
        successMessage: '',
        successOpen: false,
        errorMessage: '', 
        errorOpen: false, 
      }));
    };
  
    const [vertical] = React.useState("bottom");
    const [horizontal ] = React.useState("right");

  return (
    <div className="Contact">
       <Snackbar
            open={state.successOpen}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
          >
            < Alert onClose={handleClose} severity="success">
              {state.successMessage}
            </Alert>
          </Snackbar>
          
          <Snackbar
            open={state.errorOpen}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
          >
            < Alert onClose={handleClose} severity="error">
              {state.errorMessage}
            </Alert>
          </Snackbar>

      <div className="title">Contact Details</div>
      <div className="subtitle">
        This is how we will get in contact with you, please select your
        preferred contact method.
      </div>

      <div className="fields">
        <div className="field">
          <TextField
            onBlur={(e) => {
              handleSave();
            }}
            fullWidth
            label={"Name"}
            type="text"
            value={state.name}
            onChange={(e) => {
              setState((state) => ({ ...state, name: e.target.value }));
            }}
            
            size="small"
          />
        </div>

        <div className="field">
          <TextField
            onBlur={(e) => {
              handleSave();
            }}
            fullWidth
            label={"Phone"}
            type="tel"
            value={state.tel}
            onChange={(e) => {
              setState((state) => ({ ...state, tel: e.target.value }));
            }}
            
            size="small"
          />
          <Checkbox
            onBlur={(e) => {
              handleSave();
            }}
            style={{
              color: color,
            }}
            checked={state.phonePreferred}
            onClick={(e) => {
              setState((state) => ({
                ...state,
                phonePreferred: !state.phonePreferred,
              }));
            }}
          />
        </div>
        <div className="field">
          <TextField
            onBlur={(e) => {
              handleSave();
            }}
            fullWidth
            label={"Email"}
            type="email"
            value={state.email}
            onChange={(e) => {
              setState((state) => ({ ...state, email: e.target.value }));
            }}
            size="small"
          />
          <Checkbox
            onBlur={(e) => {
              handleSave();
            }}
            style={{
              color: color,
            }}
            checked={!state.phonePreferred}
            onClick={(e) => {
              setState((state) => ({
                ...state,
                phonePreferred: !state.phonePreferred,
              }));
            }}
          />
        </div>

        <div className="address-field">
          <div className="label">Job Address</div>
          <GooglePlacesAutocomplete
            apiKey="AIzaSyD8W33lqsmVES3MNMUnmJMrHAUnXre_wok"
           
            selectProps={{
              value,
              onChange: setValue,
              onBlur: handleSave
            }}
            apiOptions={{ region: "AU" }}
          />
        </div>
      </div>
    </div>
  );
}

export default Contact;
