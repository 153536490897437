import { Email, LocationOn, Phone } from '@mui/icons-material'
import React from 'react'
import { useParams } from 'react-router-dom'
import { db } from '../../../../Firebase';
import './Styles/CompanyDetails.css'

function CompanyDetails() {

    // hooks
    const {user} = useParams();

    // state variables
    const [state, setState] = React.useState({
        name: '', 
        city: '', 
        email: '', 
        phone: '', 
        logo: '', 
        color: 'gray'
    })

    React.useEffect(() => {
        if (user) {
            db.collection('users')
            .doc(user)
            .get()
            .then((r) => {
                const {name, city, email, phone, logo, color} = r.data();
                setState((state) => ({
                    ...state,
                    name: name ? name : '' , 
                    city: city ? city : '' , 
                    email: email ? email : '' , 
                    phone:  phone ? phone : '', 
                    logo: logo ? logo : '' , 
                    color: color ? color : 'gray',


                }))
            })

        } else {
            return;
        }
     
    }, [])
    

    
  return (
    <div className='CompanyDetails'>
        <img className='logo' src={state.logo} alt={state.name} /> 

        <div className='details'> 
        <div className='company-name' style={{color: state.color}}> 
{state.name}        </div>
        <div className='contact-item'> 
       <LocationOn style={{color: state.color, marginRight:'10px'}}/> {state.city}
        </div> 
        <div className='contact-item'> 
       <Email style={{color: state.color, marginRight:'10px'}}/> {state.email}
        </div> 
        <div className='contact-item'> 
       <Phone style={{color: state.color, marginRight:'10px'}}/> {state.phone}
        </div> 

        <div className='subtitle' style={{color:state.color}}> 
        Ready to send a service request?

        </div>


        </div>


    </div>
  )
}

export default CompanyDetails