import { Alert, Snackbar, TextField } from '@mui/material'
import React from 'react'
import { useAuth } from '../../../../Contexts/AuthContext'
import { db } from '../../../../Firebase';
import './Styles/Messages.css'

function Messages() {

  // hooks 
  const {currentUser} =  useAuth();


  // state variables
  const [state, setState] = React.useState({
    loading: false, 
    errorOpen: false, 
    errorMessage: '', 
    successOpen: false, 
    successMessage: '',
    message: ''
  })

  React.useEffect(() => {
  db.collection('requests')
  .doc(currentUser.uid)
  .get() 
  .then((r) => { 
    if (r.exists) {
      // set state
      setState((state) => ({
        ...state, 
        message: r.data() && r.data().message ? r.data().message : ''
      }))
    } else {
      return;
    }
  })
  }, [currentUser])


  function handleUpdate () { 
    db.collection('requests')
    .doc(currentUser.uid)
    .set({message: state.message, confirm: false}, {merge:true})
    .then((r) => {
      // success
      setState((state) => ({
        ...state, 
        errorOpen: false, 
        errorMessage: '', 
        successMessage: 'Successfully saved progress...',
        successOpen: true
      }))
    }).catch((err) => {
      // error
      setState((state) => ({
        ...state, 
        errorOpen: true, 
        errorMessage: 'Failed to save progress...', 
        successMessage: '',
        successOpen: true
      }))
    })

  }
  


    // handle snackbar jazz 
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setState((state) => ({
        ...state,
        successMessage: '',
        successOpen: false,
        errorMessage: '', 
        errorOpen: false, 
        infoMessage: '', 
        infoOpen: false
      }));
    };
  
    const [vertical] = React.useState("bottom");
    const [horizontal ] = React.useState("right");



  return (
    <div className='Messages'>
        <Snackbar
            open={state.successOpen}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
          >
            < Alert onClose={handleClose} severity="success">
              {state.successMessage}
            </Alert>
          </Snackbar>
          
          <Snackbar
            open={state.errorOpen}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
          >
            < Alert onClose={handleClose} severity="error">
              {state.errorMessage}
            </Alert>
          </Snackbar>
        <div className='title'>
            Description of works
        </div> 
        <TextField 
        label='Description of works...'
        placeholder='Be as descriptive as possible, this gives a starting point on knowing what is required...'
        multiline
        minRows={4}
        fullWidth  
        value={state.message}
        onChange={((e) => {
          setState((state) => ({
            ...state, 
            message: e.target.value
          }))
        })}    
        onBlur={((e) => {
          handleUpdate()
        })} 
        />



    </div>
  )
}

export default Messages