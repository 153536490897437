import './App.css';
import { BrowserRouter  as Router, Switch, Route } from "react-router-dom"
import { createBrowserHistory } from 'history';
import Home from './Pages/Home/Home';
import Redirect from './Pages/Redirect/Redirect';
import { AuthProvider } from './Contexts/AuthContext';


function App() {
  const history = createBrowserHistory()
  return (

    <Router history={history} >
        <AuthProvider> 
    <Switch>
    < Route exact path ="/submit/:user" component={Home} />
    < Route exact path ="/" component={Home} />
     
</Switch> 
</AuthProvider>
  </Router>
  );
}

export default App;
