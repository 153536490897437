import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import 'firebase/compat/firestore';
import 'firebase/compat/storage'

const app = firebase.initializeApp({
    apiKey: "AIzaSyDFo9TBJm0wzMvfoTe0UeylMsque70CIX8",
  authDomain: "tradiemagnet.firebaseapp.com",
  projectId: "tradiemagnet",
  storageBucket: "tradiemagnet.appspot.com",
  messagingSenderId: "400150031032",
  appId: "1:400150031032:web:e9c7d57e5496b294fe224d",
  measurementId: "G-05GF5N7GZQ"
})
export const auth = app.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();

export default app 
