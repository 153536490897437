import { Check } from "@mui/icons-material";
import { Checkbox, Slider, TextField } from "@mui/material";
import React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useAuth } from "../../../../Contexts/AuthContext";
import { db } from "../../../../Firebase";
import "./Styles/Confirm.css";

function Confirm({ color }) {
  //hooks
  const { currentUser } = useAuth();

  // state variables
  const [state, setState] = React.useState({
    images: [],
    description: "",
    urgency: 50,
    name: "",
    tel: "",
    email: "",
    address: {},
    confirm: false,
    phonePreferred: true
  });

  React.useEffect(() => {
    db.collection("requests")
      .doc(currentUser.uid)
      .get()
      .then((r) => {
        // set state
        setState((state) => ({
          ...state,
          images: r.data() && r.data().images ? r.data().images : [],
          description: r.data() && r.data().message ? r.data().message : "",
          urgency: r.data() && r.data().urgency ? r.data().urgency : 50,
          name: r.data() && r.data().name ? r.data().name : "",
          tel: r.data() && r.data().tel ? r.data().tel : "",
          email: r.data() && r.data().email ? r.data().email : "",
          address: r.data() && r.data().address ? r.data().address : {},
          confirm: r.data() && r.data().confirm ? r.data().confirm : false,
          phonePreferred: r.data() && r.data().phonePreferred ? r.data().phonePreferred : false,
        }));
      });
  }, [currentUser]);

  function handleConfirm () { 
    db.collection('requests')
    .doc(currentUser.uid)
    .update({confirm: !state.confirm})
  }

  return (
    <div className="Confirm">
      <div className="title">Confirm Request Details</div>
      <div className="subtitle">
        Ensure all details are accurate and correct before sending request.
      </div>

      <div className="sections">
        <div className="subheading">photos</div>
        <div className="images">
          {state.images && state.images.map((item, ind) => (
            <img className="image" src={item.url} key={ind}/>
          ))} 
        </div>
        <div className="subheading">description</div>
        <div className="description"> 
        {state.description}

        </div>
        <div className="subheading">Urgency</div>
        <div className="slider">
        <div className="helper">Not Very</div>
        <Slider
          style={{ marginLeft: "5px", marginRight: "10px", color }}
          valueLabelDisplay="off"
          value={state.urgency}
          disabled
        />
        <div className="helper">Extremely</div>
      </div>
        <div className="subheading">contact details</div>
        <div className="fields">
        <div className="field">
          <TextField
            fullWidth
            label={"Name"}
            type="text"
            value={state.name}
            size="small"
            disabled
          />
        </div>

        <div className="field">
          <TextField
            fullWidth
            label={"Phone"}
            type="tel"
            value={state.tel}
            disabled
            size="small"
          />
          <Checkbox
            style={{
              color: color,
            }}
            checked={state.phonePreferred}
            disabled
          />
        </div>
        <div className="field">
          <TextField
            
            fullWidth
            label={"Email"}
            type="email"
            value={state.email}
           disabled
            size="small"
          />
          <Checkbox
           
            style={{
              color: color,
            }}
            checked={!state.phonePreferred}
            disabled
          />
        </div>

        <div className="address-field">
          <TextField
          defaultValue={'x'}
            fullWidth
            label={"Address"}
            type="text"
            value={state.address.label}
           disabled
            size="small"
          />

         
        </div>
      </div>
      <div className="subheading">confirm</div>
        <div className="confirmation">
          <Checkbox style={{ color }} onChange={(async (e) => {setState((state) => ({...state, confirm: !state.confirm})); handleConfirm()})} checked={state.confirm}/>
          <div className="message">
            I confirm the details i've provided are accurate.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Confirm;
