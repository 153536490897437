import styled from '@emotion/styled';
import { Message } from '@mui/icons-material';
import { Alert, Button, Card, CircularProgress, LinearProgress, Snackbar } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom';
import { useAuth } from '../../Contexts/AuthContext';
import { auth, db } from '../../Firebase';
import CompanyDetails from './Slides/Company Details/CompanyDetails';
import Confirm from './Slides/Confirm/Confirm';
import Contact from './Slides/Contact/Contact';
import JobPhotos from './Slides/Job Photos/JobPhotos';
import Messages from './Slides/Message/Messages';
import Urgency from './Slides/Urgency/Urgency';
import './Styles/Home.css'

  

function Home() {
    const {user} = useParams();
    const {currentUser} = useAuth();


    const [state, setState] = React.useState({
        color: 'rgb(18, 100, 100)', 
        ind: 0, 
        loading: true, 
        redirect_url: '', 
        successMessage: '', 
        errorMessage: '', 
        errorOpen: false, 
        successOpen: false
    })
    React.useEffect( () => {

        async function createUser () {
if (currentUser) {
            return;
        } else {
            await auth.signInAnonymously().then((r) => {
                setState((state) => ({
                    ...state, 
                    loading: false, 
                }))
            })
        }
        }
        createUser()
        
    }, [currentUser])
    


    React.useEffect(() => {
        if (user) {
            db.collection('users')
            .doc(user)
            .get()
            .then((r) => {
                setState((state) => ({
                    ...state, 
                    color: r.data().color, 
                    loading: false,
                    redirect_url:  r.data().redirect_url
                }))
            })

        } else {
            return;
        }
    }, [user])


    const slides = [
        <CompanyDetails color={state.color}/>, 
        <JobPhotos color={state.color}/>, 
        <Messages color={state.color}/>, 
        <Urgency color={state.color}/>, 
        <Contact color={state.color}/>, 
        <Confirm color={state.color}/>
    ]

    function handlePageIncrease () {
        if (state.ind === slides.length - 1) {
         db.collection('requests')
         .doc(currentUser.uid)
         .get()
         .then(async (r) => {
            //success
            if (r.data().confirm) {
                await setState((state) => ({
                    ...state, 
                    loading: true
                }))

                db.collection('requests')
                .doc(currentUser.uid)
                .collection('confirmation')
                .doc(currentUser.uid)
                .set({
                    process: true
                }).then((x) => {
                    //success
                    setState((state) => ({
                        ...state, 
                        loading: false, 
                        errorOpen: false, 
                        successMessage: 'Successfully processed request! check your email for a confirmation message... you will be redirected shortly...', 
                        successOpen: true, 
                        errorMessage: ''
                    }))

                    setTimeout(() => {
                        window.location.replace(state.redirect_url)
                        
                    }, 3000);

                }).catch((er) => {
                    setState((state) => ({
                        ...state, 
                        loading: false, 
                        errorOpen: true, 
                        errorMessage: 'Failed to complete form, please try again...', 
                        successOpen: false, 
                        successMessage: ''
                    }))
                })




            } else {
                window.alert('Oops... Looks like you forgot to confirm your details...')
            }

         }).catch((err) => {
            //error
         })
            
            return null; 

        } else {
            setState((state) => ({
                ...state, 
                ind: state.ind + 1
            }))
        }


    }
    
    function handlePageDecrease () { 
        if (state.ind === 0) {
            return;
        } else {
            setState((state) => ({
                ...state, 
                ind: state.ind - 1
            }))
        }
    }


    const Progress = styled(LinearProgress)(({ theme }) => ({
        background: `${state.color}40`, 
        
          "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: state.color,
          },
      }));

    const {innerHeight, innerWidth} = window


    const progress = (100 / slides.length  ) * (state.ind )

    // handle snackbar jazz 
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setState((state) => ({
          ...state,
          successMessage: '',
          successOpen: false,
          errorMessage: '', 
          errorOpen: false, 
        }));
      };
    
      const [vertical] = React.useState("bottom");
      const [horizontal ] = React.useState("right");

  return (
    <div className='Home'>
         <Snackbar
            open={state.successOpen}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
          >
            < Alert onClose={handleClose} severity="success">
              {state.successMessage}
            </Alert>
          </Snackbar>
          
          <Snackbar
            open={state.errorOpen}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
          >
            < Alert onClose={handleClose} severity="error">
              {state.errorMessage}
            </Alert>
          </Snackbar>
        {state.loading && 
        <div className='loading'> 
        <CircularProgress style={{color: state.color}}/>
            </div>}
        <Card className='card' style={{width:innerWidth - 20, height: innerHeight - 30}}> 
        <Progress value={progress} variant='determinate' />
        {slides[state.ind]}


        <div className='nav-buttons'> 
        <Button style={{color: state.color}} onClick={((e) => {
            handlePageDecrease()
        })}
        disabled={state.ind === 0}
        >
            Previous
        </Button>
        <Button style={{color: 'white', backgroundColor: state.color}} onClick={((e) => {
            handlePageIncrease()
        })}>
            {state.ind + 1 === slides.length ? 'Submit' : (state.ind === 0 ? 'Begin' : 'Next')}
        </Button>

        </div>
        </Card>
        

    </div>
  )
}

export default Home