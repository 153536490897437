import { Alert, Slider, Snackbar } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import "./Styles/Urgency.css";
import { db } from "../../../../Firebase";
import styled from "@emotion/styled";
import { useAuth } from "../../../../Contexts/AuthContext";

function Urgency({ color }) {
  // hooks
  const { currentUser } = useAuth();

  // state variables
  const [state, setState] = React.useState({
    loading: false,
    errorMessage: "",
    successMessage: "",
    errorOpen: false,
    successOpen: false,
    amount: 50,
  });

  const ColorSlider = styled(Slider)(({ theme }) => ({
    // background: `${state.color}40`,

    "& .MuiSlider-rail": {
      backgroundColor: color,
    },
    "& .css-1gv0vcd-MuiSlider-track": {
      backgroundColor: color,
      borderColor: color,
    },
    "& .css-eg0mwd-MuiSlider-thumb": {
      backgroundColor: color,
      borderColor: color,
    },
  }));

  // handle update

  function handleSave() {
    db.collection("requests")
      .doc(currentUser.uid)
      .set(
        {
          urgency: state.amount,
          confirm: false,
        },
        { merge: true }
      )
      .then((r) => {
        // success
        setState((state) => ({
          ...state,
          errorMessage: "",
          errorOpen: false,
          successMessage: "Successfully saved progress...",
          successOpen: true,
        }));
      })
      .catch((err) => {
        // error
        setState((state) => ({
          ...state,
          errorMessage: "Failed to save progress...",
          errorOpen: true,
          successMessage: "",
          successOpen: false,
        }));
      });
  }

  React.useEffect(() => {
    db.collection("requests")
      .doc(currentUser.uid)
      .get()
      .then((r) => {
        setState((state) => ({
          ...state,
          amount: r.data() && r.data().urgency ? r.data().urgency : 50,
        }));
      });
  }, [currentUser]);


      // handle snackbar jazz 
      const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setState((state) => ({
          ...state,
          successMessage: '',
          successOpen: false,
          errorMessage: '', 
          errorOpen: false, 
          
        }));
      };
    
      const [vertical] = React.useState("bottom");
      const [horizontal ] = React.useState("right");


  return (
    <div className="Urgency">
       <Snackbar
            open={state.successOpen}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
          >
            < Alert onClose={handleClose} severity="success">
              {state.successMessage}
            </Alert>
          </Snackbar>
          
          <Snackbar
            open={state.errorOpen}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
          >
            < Alert onClose={handleClose} severity="error">
              {state.errorMessage}
            </Alert>
          </Snackbar>
      <div className="title">Request Urgency</div>
      <div className="subtitle">How Urgent is the work?</div>

      <div className="slider">
        <div className="helper">Not Very</div>
        <Slider
          style={{ marginLeft: "5px", marginRight: "10px", color }}
          valueLabelDisplay="off"
          value={state.amount}
          onChange={(e) => {
            console.log(e.target.value)
            setState((state) => ({
              ...state,
              amount: e.target.value,
            }));
          }}
          onBlur={((e) => {
            console.log('blur')
            handleSave();
          })}
        />
        <div className="helper">Extremely</div>
      </div>
    </div>
  );
}

export default Urgency;
